import React from 'react';
import './App.css';
import logo1 from './realestate.png'; // Replace with your logo path
import logo2 from './luxury.png'; // Replace with your logo path
import logo3 from './living.png'; // Replace with your logo path

function App() {
  return (
    <div className="App">
      <div className="logo-container">
        <a href="https://sunsetestates.com.do" target="_blank" rel="noopener noreferrer" className="logo-box white">
          <img src={logo1} alt="Samana Real Estate" loading="lazy" />
        </a>
        <a href="https://sunsetestates.com.do" target="_blank" rel="noopener noreferrer" className="logo-box black">
          <img src={logo2} alt="Samana Luxury Rentals" loading="lazy" />
        </a>
        <a href="https://sunsetestates.com.do" target="_blank" rel="noopener noreferrer" className="logo-box custom">
          <img src={logo3} alt="Living in Samana" loading="lazy" />
        </a>
      </div>
    </div>
  );
}

export default App;